import { createApp,markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import directives from "./directives/";

import "@/assets/css/tooltip.scss";

//import bootstrap from 'bootstrap'
//import "@popperjs/core/dist/cjs/popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";


import '@stripe/stripe-js'


import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

// import { faSignOutAlt,faLongArrowAltRight,faStar,faStarHalfAlt,faMapMarkerAlt,faPhoneAlt,faCheck,faLeaf,
//         faAngleRight,faLocationArrow,faArrowRight,faShare,faHeart,faSlidersH,faCheckCircle}
//         from '@fortawesome/free-solid-svg-icons'

//faSignOutAlt,faLongArrowAltRight,faStar,faStarHalfAlt,
//faMapMarkerAlt,faPhoneAlt,,faCheck,faLeaf,faAngleRight,faLocationArrow,faArrowRight,faShare,faHeart,
//faSlidersH,,faCheckCircle

import { faFacebookF,faYoutube, faLinkedinIn, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faTimesCircle,faTrashAlt,faEdit,faBell,faClock,faMap,faComments,faEye,faEyeSlash,faStar,faUser,faGem,faHeart,faEnvelope,faCircle} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas,faFacebookF,faYoutube,faLinkedinIn,faTwitter,faWhatsapp,
    faTimesCircle,faTrashAlt,faEdit,faBell,faMap,faComments,faClock,faEye,faEyeSlash,faStar,faUser,faGem,faHeart,faEnvelope,faCircle)


//import x5GMaps from 'x5-gmaps'
//import '@/assets/css/style.css'

window.$ = window.jQuery = require('jquery')

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import Vue3Storage from "vue3-storage";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSocialSharing from 'vue-social-sharing'

import axios from 'axios'
const pinia = createPinia()
pinia.use(({ store }) => {
    store.router = markRaw(router)
    store.axios = markRaw(axios)
})
const app = createApp(App)
// .use(store)
.use(router)
.use(VueSocialSharing)
//.use(bootstrap)
.use(pinia)
.use(VueSweetalert2)
.use(Vue3Storage, {namespace: "pro_"})
.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 10,
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
    newestOnTop: false,
    position: "bottom-left",
})
.component('fa', FontAwesomeIcon);
directives(app);
//app.directive("tooltip", tooltip)
app.mount('#app')
